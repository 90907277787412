<template>
  <div id="home">
    <div class="welcome-block">
      <div class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <div class="rotating-logo">
            <img :src="DarkLogo" alt="logo" />
<!--              <img v-if="theme === 'dark'" :src="LightLogo" alt="logo" />-->
          </div>
          <h1>Karaoke Studio D<sup>TM</sup></h1>
        </div>
      </div>
      <p>{{ $t("welcome_message") }}</p>
    </div>
    <div class="landing-artists-sliders">
      <div class="see-all">
        <router-link to="/artists" class="button button-primary sm">
          View All Artists
        </router-link>
      </div>
      <div class="slider slider-1">
        <div class="slide-track">
          <div
              v-for="(artist, i) in randomArtistsFirstRow"
              :key="i + '-artist'"
              class="slide"
          >
            <img :src="artists[artist].src" alt="" />-->
          </div>
          <div
              v-for="(artist, i) in randomArtistsFirstRow"
              :key="i + '-artist'"
              class="slide"
          >
            <img :src="artists[artist].src" alt="" />-->
          </div>
        </div>
      </div>
      <div class="slider slider-2">
        <div class="slide-track">
          <div
              v-for="(artist, i) in randomArtistsSecondRow"
              :key="i + '-artist'"
              class="slide"
          >
            <img :src="artists[artist].src" alt="" />-->
          </div>
          <div
              v-for="(artist, i) in randomArtistsSecondRow"
              :key="i + '-artist'"
              class="slide"
          >
            <img :src="artists[artist].src" alt="" />-->
          </div>
        </div>
      </div>
      <div class="slider slider-3">
        <div class="slide-track">
          <div
              v-for="(artist, i) in randomArtistsThirdRow"
              :key="i + '-artist'"
              class="slide"
          >
            <img :src="artists[artist].src" alt="" />-->
          </div>
          <div
              v-for="(artist, i) in randomArtistsThirdRow"
              :key="i + '-artist'"
              class="slide"
          >
            <img :src="artists[artist].src" alt="" />-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getNews, createNews, editNews, deleteNews } from "@/services/news";
import { getArtists, uploadImage } from "@/services/songs";
import DarkLogo from "@/assets/logo.svg";
import LightLogo from "@/assets/logo-white.svg";

export default {
  name: "home",
  data() {
    return {
      theme: "light",
      artists: [],
      randomArtistsFirstRow: [],
      randomArtistsSecondRow: [],
      randomArtistsThirdRow: [],
      loading: true,
      DarkLogo,
      LightLogo
    };
  },
  computed: {
    isLogged() {
      return this.$store.state.isLogged;
    },
  },
  methods: {
    // selectArtist: function (artist) {
    //   this.$router.push("/artist/" + artist.name);
    // },
    tryGetArtists() {
      let self = this;
      getArtists(function (data) {
        if (data.error) {
          self.$emit("show-snackbar", data.payload.data.status, "error");
          self.$router.push("/");
        } else {
          data.payload.forEach((item) => {
            let src = "/api/stream/" + item.replace(/ /g, "") + ".jpg";
            let artist = {
              name: item,
              src,
            };
            self.artists.push(artist);
            self.shuffledArtists = self.artists.sort(
                () => self.artists.length * Math.random()
            );
            // self.shuffledArtists = self.artists.slice(0, 8)
          });
        }
      });
    },
    getRandomArtists() {
      const self = this;
      const arr = [];
      for (let i = 1; i <= self.artists.length; i++) {
        arr.push(i);
      }
      const randomElement = arr.sort(() => 0.5 - Math.random());
      self.randomArtistsFirstRow = randomElement.slice(0, 12);
      self.randomArtistsSecondRow = randomElement.slice(13,24);
      self.randomArtistsThirdRow = randomElement.slice(25, 36);
      self.artists.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
    },
    getTheme() {
      if (localStorage.getItem("theme")) {
        const selectedTheme = localStorage.getItem("theme");
        this.theme = selectedTheme;
      } else {
        localStorage.setItem("theme", "light");
        const selectedTheme = localStorage.getItem("theme");
        this.theme = selectedTheme;
      }
    },
  },
  mounted() {
    this.getTheme();
    this.tryGetArtists();
    setTimeout(() => {
      this.getRandomArtists();
      // this.loading = false;
    }, 600)
  },
};
</script>
<style lang="scss" scoped></style>
