<template>
  <div id="Playlists" class="px-4">
    <div class="d-flex align-center custom-input-wrapper">
      <v-text-field
          v-model="playlist.playlistName"
          hide-details
          placeholder="New playlist name"
          class="w-auto"
      ></v-text-field>
      <button @click="newPlaylist()">Add new playlist</button>
    </div>
    <v-row dense>
      <v-col v-for="(playlist, i) in playlists" :key="i" cols="12">
        <v-lazy>
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="pt-3 mt-3 playlist"
              rounded="xl"
              :elevation="hover ? 16 : 2"
              @click.native="selectPlaylist(playlist)"
            >
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title
                    :class="[
                      $vuetify.breakpoint.mdAndUp ? 'headline' : 'subtitle-1',
                    ]"
                    >{{ playlist.playlistName }}</v-card-title
                  >
                </div>
                <v-avatar class="ma-3" size="125" tile>
                  <v-icon large>mdi-music-note-outline</v-icon>
                </v-avatar>
              </div>
              <v-btn
                class="mx-2"
                fab
                dark
                bottom
                right
                x-small
                absolute
                color="deep-orange darken-4"
                @click.native="deletePlaylist(playlist, i)"
              >
                <v-icon dark>mdi-minus</v-icon>
              </v-btn>
            </v-card>
          </v-hover>
        </v-lazy>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { deletePlaylist, getPlaylists, createPlaylist } from "@/services/users";

export default {
  name: "playlists",
  data() {
    return {
      playlist: {
        playlistName: '',
      },
      playlists: [],
    };
  },
  computed: {
    isLogged() {
      return this.$store.state.isLogged;
    },
    // playlist() {
    //   return this.playlists.filter(
    //       (el) => el.playlistName === this.$route.params.name
    //   );
    // },
  },
  methods: {
    selectPlaylist: function (playlist) {
      this.$router.push("/playlists/" + playlist.playlistName);
    },
    addPlaylist(playlist) {
      this.$emit("add-paylist", {
        playlist,
      });
    },
    newPlaylist: function () {
      let self = this;
      let props = {
        playlist: this.playlist,
      };
      this.playlists.push(props.playlist)
      createPlaylist(props,function (data) {
        if (!data.error) {
          self.$emit("show-snackbar", data.payload.status, "success");
        } else {
          self.$emit("show-snackbar", data.payload.data.status, "error");
        }
      })
    },
    deletePlaylist: function (playlist, index) {
      let self = this;
      deletePlaylist(playlist._id, function (data) {
        if (data.error) {
          self.$emit("show-snackbar", data.payload.data.status, "error");
        } else {
          self.$emit("show-snackbar", "Playlist Deleted", "success");
          self.playlists.splice(index, 1);
        }
      });
    },
  },
  beforeMount() {
    let self = this;
    getPlaylists(function (data) {
      if (data.error) {
        self.$emit("show-snackbar", data.payload.data.status, "error");
      } else {
        self.playlists = data.payload.playlists;
      }
    });
  },
};
</script>
<style lang="scss" scoped></style>
