<template>
  <v-container class="text-center" fill-height>
    <v-row align="center">
      <v-col>
        <h1 class="display-2 primary--text">{{ $t("error.404.title") }}</h1>

        <p>{{ $t("error.404.content") }}</p>

        <v-btn :to="`/`" color="primary" outlined>
          {{ $t("error.404.href") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "not_found",
  created() {
    this.$ssrContext && this.$ssrContext.res.status(404);
  },
};
</script>
<style lang="scss" scoped></style>
