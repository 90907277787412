<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card class="login-form text-xs-center">
          <div class="display-1 mb-3">
            <v-card-text>
              <div class="subheading">
                <v-card-title>{{ $t("registration") }}</v-card-title>
              </div>
              <v-form ref="signUpForm" v-model="isValid">
                <v-text-field
                  v-model="user.email"
                  light="light"
                  prepend-icon="email"
                  label="Email"
                  type="email"
                  :rules="emailRules"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="user.password"
                  light="light"
                  prepend-icon="lock"
                  label="Šifra"
                  :rules="passwordRules"
                  required
                  type="password"
                ></v-text-field>
                <v-text-field
                  v-model="repeatPassword"
                  light="light"
                  prepend-icon="lock"
                  label="Ponovite Šifru"
                  :rules="repeatPasswordRules"
                  required
                  type="password"
                ></v-text-field>
                <v-text-field
                  v-model="refId"
                  light="light"
                  prepend-icon="lock"
                  disabled
                  label="Referrer ID"
                  required
                ></v-text-field>
                <v-btn
                  class="mb-2"
                  block="block"
                  type="submit"
                  @click="signUp"
                  @click.prevent
                  color="primary"
                  >{{ $t("register") }}</v-btn
                >
              </v-form>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="mailDialog" width="300">
      <v-card>
        <v-card-title>{{ $t("email") }}</v-card-title>
        <v-card-text>
          {{ $t("we_have_sent_link_for_registration_on_email") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeMailDialog"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { getUserRefUrl, registerUser } from "@/services/users";

export default {
  props: {
    refId: {
      type: String,
      default: null,
    },
  },
  computed: {
    repeatPasswordRules() {
      return [
        () =>
          this.user.password === this.repeatPassword ||
          "Šifre se ne podudaraju",
        (v) => !!v || "Molim Vas ponovite šifru",
      ];
    },
  },
  data: () => ({
    user: {},
    isValid: true,
    mailDialog: false,
    emailRules: [
      (v) => !!v || "Email polje je neophodno za registraciju",
      (v) => /.+@.+\..+/.test(v) || "Email mora biti validan",
    ],
    passwordRules: [
      (v) => !!v || "Šifra je neophodna za registraciju",
      (v) =>
        /^(?=[a-zA-Z0-9#@$?\!]{5,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).*/.test(
          v
        ) ||
        "Šifra mora da sadrži jedno veliko jedno malo slovo i bar jedan broj",
    ],
    requiredRules: [(v) => !!v || "Ovo polje je neophodno"],
  }),
  methods: {
    signUp: function () {
      if (this.isValid) {
        let self = this;
        let payload = {
          user: this.user,
          hostName: location.origin,
          referrer: this.refId,
        };
        registerUser(payload, function (data) {
          if (data.error) {
            self.$emit("show-snackbar", data.payload.data.status, "error");
          } else {
            self.$emit(
              "show-snackbar",
              "Uspešno ste se registrovali proverite email za potvrdu!",
              "success"
            );
            self.mailDialog = true;
            self.$refs.signUpForm.reset();
            self.$refs.signUpForm.resetValidation();
          }
        });
      } else {
        this.$refs.signUpForm.validate();
      }
    },
    closeMailDialog: function () {
      this.mailDialog = false;
      this.$router.push("/");
    },
  },
  beforeMount() {
    //do something before mounting vue instance
    let self = this;
    getUserRefUrl(this.refId, function (data) {
      if (data.error) {
        self.$emit("show-snackbar", data.payload.data.status, "error");
        self.$router.push("/404");
      } else {
        self.$emit("show-snackbar", data.payload.status, "success");
      }
    });
  },
};
</script>
<style lang="scss" scoped></style>
