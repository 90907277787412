<template>
  <div id="SingleArtist" class="single-artist">
    <v-container>
      <button
        v-if="isAdmin || isEditing"
        @click="dialogNew = true"
        class="action-button inverted mb-3"
      >
        <plus-icon />
      </button>
      <v-row dense autofocus>
        <v-col v-for="(song, i) in songs" :key="i" cols="12">
          <v-lazy>
            <v-hover v-slot:default="{ hover }">
              <v-card class="song-wrapper p-rela">
                <div
                  class="d-flex flex-no-wrap justify-space-between"
                  v-on:click="selectSong(song)"
                  autofocus
                >
                  <div>
                    <v-card-title
                      :class="[
                        $vuetify.breakpoint.mdAndUp ? 'headline' : 'subtitle-1',
                      ]"
                      v-text="song.songName"
                    ></v-card-title>
                    <v-card-subtitle v-text="song.artist"></v-card-subtitle>
                  </div>
                  <v-avatar v-if="song.image" class="ma-3" size="125">
                    <v-img :src="'/api/stream/' + song.image"></v-img>
                  </v-avatar>
                </div>
                <div class="buttons-wrapper pl-4 pb-4 mt-0">
                  <button
                    v-if="isLogged"
                    @click="addSongToPlaylist(song)"
                    class="action-button action-button__primary sm"
                  >
                    <playlist-icon />
                  </button>
                  <button
                    v-if="isAdmin || isEditing"
                    @click="editSongButton(song, i)"
                    class="action-button action-button__success sm"
                  >
                    <edit-icon />
                  </button>
                  <button
                    v-if="isAdmin"
                    @click="deleteSongButton(song, i)"
                    class="action-button action-button__danger sm"
                  >
                    <trash-icon />
                  </button>
                </div>
              </v-card>
            </v-hover>
          </v-lazy>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      content-class="song-dialog"
    >
      <v-card>
        <form class="custom-input-wrapper">
          <v-toolbar>
            <button
              type="button"
              @click="closeModal"
              class="action-button action-button__danger inverted"
            >
              <close-icon />
            </button>
            <v-toolbar-title
              >Edit song: {{ selectedSong.artist }} -
              {{ selectedSong.songName }}</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <button @click="editSong" class="button button-primary sm">
                {{ $t("save") }}
              </button>
            </v-toolbar-items>
          </v-toolbar>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="selectedSong.artist"
                  label="Artist"
                ></v-text-field>
                <v-text-field
                  v-model="selectedSong.songName"
                  label="Song Name"
                ></v-text-field>
                <v-select
                  v-model="selectedSong.artistOrigin"
                  :items="originItems"
                  label="Origin"
                ></v-select>
                <v-text-field
                  v-model="selectedSong.genre"
                  label="Genre"
                ></v-text-field>
                <v-text-field
                  v-model="selectedSong.views"
                  label="Views"
                ></v-text-field>
                <v-text-field
                  v-model="selectedSong.trueViews"
                  label="True Views"
                  disabled
                ></v-text-field>
                <v-text-field
                  v-model="selectedSong.releaseYear"
                  label="Release Year"
                ></v-text-field>
                <v-textarea
                  v-model="selectedSong.tags"
                  label="Tags"
                  rows="2"
                ></v-textarea>
              </v-col>
              <v-col>
                <v-textarea
                  rows="10"
                  v-model="selectedSong.textContent"
                  label="Lyrics"
                ></v-textarea>
                <v-avatar
                  v-if="this.newImage.src || this.selectedSong.image"
                  class="ma-3"
                  size="150"
                  tile
                >
                  <v-img :src="selectedSongImage"></v-img>
                </v-avatar>
                <v-row>
                  <v-col cols="5">
                    <v-text-field
                      v-model="selectedSong.image"
                      label="Image"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="5">
                    <v-file-input
                      accept="image/png, image/jpeg, image/bmp"
                      placeholder="Upload new image"
                      prepend-icon="mdi-camera"
                      v-on:change="imageChanged($event)"
                      label="Image"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      v-if="!imageUploading"
                      icon
                      x-large
                      @click="uploadNewImage"
                      :disabled="!newImage.src"
                      class="publish"
                    >
                      <v-icon>mdi-publish</v-icon>
                    </v-btn>
                    <v-progress-circular
                      v-else
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5">
                    <v-text-field
                      v-model="selectedSong.fileName"
                      label="Song File"
                      disabled
                    ></v-text-field
                  ></v-col>
                  <v-col cols="5">
                    <v-file-input
                      accept="audio/*"
                      placeholder="Upload new song file"
                      prepend-icon="mdi-music"
                      v-on:change="audioChanged($event)"
                      label="Song"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      v-if="!songUploading"
                      icon
                      x-large
                      @click="uploadEditedSong"
                      :disabled="!newSong"
                      class="publish"
                    >
                      <v-icon>mdi-publish</v-icon>
                    </v-btn>
                    <v-progress-circular
                      v-else
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5">
                    <v-text-field
                      v-model="selectedSong.videoName"
                      label="Video File"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="5">
                    <v-file-input
                      accept="video/*"
                      placeholder="Upload new video file"
                      prepend-icon="mdi-video"
                      v-on:change="videoChanged($event)"
                      label="Video"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      v-if="!videoUploading"
                      icon
                      x-large
                      @click="uploadNewVideo"
                      :disabled="!newVideo"
                      class="publish"
                    >
                      <v-icon>mdi-publish</v-icon>
                    </v-btn>
                    <v-progress-circular
                      v-else
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogNew"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      content-class="song-dialog"
    >
      <v-card>
        <form class="custom-input-wrapper">
          <v-toolbar>
            <button
              type="button"
              @click="closeNewModal"
              class="action-button action-button__danger inverted"
            >
              <close-icon />
            </button>
            <v-toolbar-title>Upload New Song</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <button
                type="button"
                @click="uploadNewSong"
                class="button button-primary sm"
              >
                {{ $t("save") }}
              </button>
            </v-toolbar-items>
          </v-toolbar>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="newSongUpload.artist"
                  label="Artist"
                ></v-text-field>
                <v-text-field
                  v-model="newSongUpload.songName"
                  label="Song Name"
                ></v-text-field>
                <v-select
                  v-model="newSongUpload.artistOrigin"
                  :items="originItems"
                  label="Origin"
                ></v-select>
                <v-text-field
                  v-model="newSongUpload.genre"
                  label="Genre"
                ></v-text-field>
                <v-text-field
                  v-model="newSongUpload.views"
                  label="Views"
                ></v-text-field>
                <v-text-field
                  v-model="newSongUpload.trueViews"
                  label="True Views"
                  disabled
                ></v-text-field>
                <v-text-field
                  v-model="newSongUpload.releaseYear"
                  label="Release Year"
                ></v-text-field>
                <v-textarea
                  v-model="newSongUpload.tags"
                  label="Tags"
                  rows="2"
                ></v-textarea>
              </v-col>
              <v-col>
                <v-textarea
                  rows="10"
                  v-model="newSongUpload.textContent"
                  label="Lyrics"
                ></v-textarea>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="newSongUpload.fileName"
                      label="Song File"
                      disabled
                    ></v-text-field
                  ></v-col>
                  <v-col>
                    <v-file-input
                      accept="audio/*"
                      placeholder="Upload new song file"
                      prepend-icon="mdi-music"
                      v-on:change="audioUploadChanged($event)"
                      label="Song"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card>
    </v-dialog>
    <v-overlay :value="newSongUploadOverlay" z-index="999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import {
  getArtistSongs,
  deleteSong,
  getSong,
  editSong,
  uploadImage,
  uploadNewSong,
  uploadSong,
  uploadVideo,
} from "@/services/songs";
import PlusIcon from "@/components/svg/PlusIcon.vue";
import EditIcon from "@/components/svg/EditIcon.vue";
import TrashIcon from "@/components/svg/TrashIcon.vue";
import PlaylistIcon from "@/components/svg/PlaylistIcon.vue";
import CloseIcon from "@/components/svg/CloseIcon.vue";

export default {
  components: {
    CloseIcon,
    PlaylistIcon,
    TrashIcon,
    EditIcon,
    PlusIcon,
  },
  computed: {
    isLogged() {
      return this.$store.state.isLogged;
    },
    loggedUser() {
      return this.$store.state.user;
    },
    isAdmin() {
      return this.$store.state.user.role === "legatus";
    },
    isEditing() {
      return this.$store.state.user.role === "editing";
    },
    selectedSongImage() {
      return this.newImage.src || "/api/stream/" + this.selectedSong.image;
    },
    imgPath() {
      let src = "/api/stream/" + this.name.replace(/ /g, "") + ".jpg";
      return src;
    }
  },
  name: "single_artist",
  metaInfo() {
    return {
      title: `Karaoke Studio D - ${this.name}`,
      meta: [
        {
          name: "description",
          content:
            "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno!",
        },
        {
          name: "twitter:card",
          content: this.name,
        },
        {
          name: "twitter:site",
          content: "@matricestudiod",
        },
        {
          name: "twitter:title",
          content: `Karaoke Studio D - ${this.name}`,
        },
        {
          name: "twitter:description",
          content:
            "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno!",
        },
        {
          name: "twitter:creator",
          content: "@matricestudiod",
        },
        {
          name: "twitter:image",
          content: `${window.location.origin}${this.imgPath}`,
        },
        {
          property: "og:title",
          content: `Karaoke Studio D - ${this.name}`,
        },
        {
          property: "og:url",
          content: `${window.location.origin}/artist/${this.name.replace(/\s/g, '-')}`,
        },
        {
          property: "og:image",
          content: `${window.location.origin}${this.imgPath}`,
        },
        {
          property: "og:description",
          content:
            "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno",
        },
        { property: "og:site_name", content: "Karaoke" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  props: {
    name: String,
  },
  data() {
    return {
      songs: [],
      dialog: false,
      dialogNew: false,
      selectedSong: {},
      newSongUpload: {
        artist: "",
        fileName: "",
        artistOrigin: "Domaća",
        duet: false,
        genre: "",
        songName: "",
        views: 0,
        trueViews: 0,
        tags: "",
        textContent: "",
        releaseYear: 2020,
        image: "",
        artists: [],
      },
      originItems: ["Domaća", "Strana"],
      newImage: {
        src: null,
      },
      newVideo: null,
      newSong: null,
      newUploadSong: null,
      songUploading: false,
      videoUploading: false,
      imageUploading: false,
      selectedSongIndex: null,
      newSongUploadOverlay: false,
    };
  },
  methods: {
    selectSong: function (song) {
      this.$router.push(
        "/artist/" + song.artist.replace(/\s/g, '-') + "/" + song.songName.replace(/\s/g, '-') + "/" + song._id
      );
    },
    editSongButton: function (song, index) {
      this.selectedSongIndex = index;
      this.selectedSong = song;
      let self = this;
      getSong(song._id, function (data) {
        if (data.error) {
          self.$emit("show-snackbar", data.payload.data.status, "error");
        } else {
          self.selectedSong = data.payload;
        }
      });
      this.dialog = true;
    },
    addSongToPlaylist(song) {
      this.$emit("add-to-paylist", {
        song,
      });
    },
    editSong: function () {
      let self = this;
      let props = this.selectedSong;
      editSong(this.selectedSong._id, props, function (data) {
        if (data.error) {
          self.$emit("show-snackbar", data.payload.data.status, "error");
        } else {
          self.$emit("show-snackbar", "Song Updated", "success");
          self.songs.splice(self.selectedSongIndex, 1, data.payload);
        }
      });
    },
    deleteSongButton: function (song, index) {
      let self = this;
      deleteSong(song._id, function (data) {
        if (data.error) {
          self.$emit("show-snackbar", data.payload.data.status, "error");
        } else {
          self.$emit("show-snackbar", "Song Deleted", "success");
          self.songs.splice(index, 1);
        }
      });
    },
    imageChanged: function (e) {
      if (e) {
        this.newImage = e;
        this.newImage.src = URL.createObjectURL(e);
      } else {
        this.newImage = {
          src: null,
        };
      }
    },
    closeModal: function () {
      this.selectedSong = {};
      this.newImage = {
        src: null,
      };
      this.dialog = false;
    },
    uploadNewImage: function () {
      this.imageUploading = true;
      let self = this;
      let formData = new FormData();
      formData.append("file", this.newImage);
      formData.append("imageName", this.selectedSong.image);
      uploadImage(formData, function (data) {
        if (data.error) {
          self.$emit("show-snackbar", data.payload.data.status, "error");
          self.imageUploading = false;
        } else {
          self.$emit("show-snackbar", "Image Uploaded", "success");
          self.imageUploading = false;
        }
      });
    },
    audioChanged: function (e) {
      if (e) {
        this.newSong = e;
      } else {
        this.newSong = null;
      }
    },
    videoChanged: function (e) {
      if (e) {
        this.newVideo = e;
        this.selectedSong.videoName = e.name;
      } else {
        this.newVideo = null;
        this.selectedSong.videoName = "";
      }
    },
    uploadEditedSong: function () {
      this.songUploading = true;
      let self = this;
      let formData = new FormData();
      formData.append("song", JSON.stringify(this.selectedSong));
      formData.append("file", this.newSong);
      uploadNewSong(formData, function (data) {
        if (data.error) {
          self.songUploading = false;
          self.$emit("show-snackbar", data.payload.data.status, "error");
        } else {
          self.songUploading = false;
          self.$emit("show-snackbar", "New Song Uploaded", "success");
        }
      });
    },
    uploadNewSong: function () {
      let self = this;
      let formData = new FormData();
      this.newSongUploadOverlay = true;
      formData.append("song", JSON.stringify(this.newSongUpload));
      formData.append("file", this.newUploadSong);
      uploadSong(formData, function (data) {
        if (data.error) {
          self.newSongUploadOverlay = false;
          self.$emit("show-snackbar", data.payload.data.status, "error");
        } else {
          self.newSongUploadOverlay = false;
          self.$emit("show-snackbar", "New Song Uploaded", "success");
        }
      });
    },
    uploadNewVideo: function () {
      let self = this;
      let formData = new FormData();
      this.videoUploading = true;
      formData.append("song", JSON.stringify(this.selectedSong));
      formData.append("file", this.newVideo);
      uploadVideo(formData, function (data) {
        if (data.error) {
          self.videoUploading = false;
          self.$emit("show-snackbar", data.payload.data.status, "error");
        } else {
          self.videoUploading = false;
          self.$emit("show-snackbar", "New Video Uploaded", "success");
        }
      });
    },
    audioUploadChanged(e) {
      if (e) {
        this.newUploadSong = e;
        this.newSongUpload.fileName = this.newUploadSong.name.replace(/ /g, "");
        let songHelper = this.newUploadSong.name.split("-");
        this.newSongUpload.artist = songHelper[0].trim();
        this.newSongUpload.songName = songHelper[1].split(".")[0].trim();
        let artistHelper = this.newSongUpload.artist.split("&");
        this.newSongUpload.duet = artistHelper.length > 1;
        let self = this;
        artistHelper.forEach(function (artist) {
          self.newSongUpload.artists.push({ name: artist.trim() });
        });
        this.newSongUpload.image =
          this.newSongUpload.artist.replace(/ /g, "") + ".jpg";
      } else {
        this.newUploadSong = null;
        this.newSongUpload = {
          artist: "",
          fileName: "",
          artistOrigin: "Domaća",
          duet: false,
          genre: "",
          songName: "",
          views: 0,
          trueViews: 0,
          tags: "",
          textContent: "",
          releaseYear: 2020,
          image: "",
          artists: [],
        };
      }
    },
    closeNewModal: function () {
      this.newSongUpload = {
        artist: "",
        fileName: "",
        artistOrigin: "Domaća",
        duet: false,
        genre: "",
        songName: "",
        views: 0,
        trueViews: 0,
        tags: "",
        textContent: "",
        releaseYear: 2020,
        image: "",
        artists: [],
      };
      this.dialogNew = false;
    },
  },
  beforeMount() {
    //do something before mounting vue instance
    let self = this;
    getArtistSongs(this.name.replace(/-/g, ' '), function (data) {
      if (data.error) {
        self.$emit("show-snackbar", data.payload.data.status, "error");
        self.$router.push("/");
      } else {
        self.songs = data.payload;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.v-avatar.outlined {
  border: 3px solid #c41e3a;
  border-radius: 50%;
  height: 56px;
  width: 56px;
}
</style>
