<template>
  <v-container>
    <div class="user-preview">
      <h1 class="page-title">User preview</h1>
      <v-row>
        <v-col>
          <div class="user-preview__single">
            <label>{{ $t("firstname") }}</label>
            <input type="text" :value="loggedUser.name" readonly />
          </div>
        </v-col>
        <v-col>
          <div class="user-preview__single">
            <label>{{ $t("surname") }}</label>
            <input type="text" :value="loggedUser.lastName" readonly />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="user-preview__single">
            <label>{{ $t("email") }}</label>
            <input type="text" :value="loggedUser.email" readonly />
          </div>
        </v-col>
        <v-col>
          <div class="user-preview__single">
            <label>{{ $t("subscribed_until") }}</label>
            <input type="text" :value="expiresDate" readonly />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="user-preview__single referral">
            <label>{{ $t("my_referrer_url") }}</label>
            <input id="copyRefUrl" :value="refUrl" readonly />
            <button class="copy-btn" @click="toggleRefCopy">
              <copy-icon />
            </button>
          </div>
        </v-col>
      </v-row>
      <div class="buttons-wrapper big">
        <button
          class="button button-primary"
          @click="passwordResetDialog = true"
          @click.prevent
        >
          {{ $t("reset_password") }}
        </button>
        <button @click="editUser" @click.prevent class="button button-primary">
          {{ $t("change_data") }}
        </button>
        <button @click="signOut" @click.prevent class="button button-primary">
          {{ $t("sign_out") }}
        </button>
      </div>
    </div>

    <v-dialog v-model="passwordResetDialog" max-width="300">
      <v-card>
        <v-card-title class="headline">{{ $t("are_you_sure") }}</v-card-title>

        <v-card-text>
          {{ $t("we_will_send_email_link_reset") }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <!--          <v-btn-->
          <!--              color="error"-->
          <!--              text-->
          <!--              @click="passwordResetDialog = false"-->
          <!--          >-->
          <!--            {{ $t("decline") }}-->
          <!--          </v-btn>-->
          <button
            class="button button-danger sm"
            @click="passwordResetDialog = false"
          >
            {{ $t("decline") }}
          </button>
          <button class="button button-primary sm" @click="resetPassword">
            {{ $t("accept") }}
          </button>

          <!--          <v-btn color="primary" text @click="resetPassword">-->
          <!--            {{ $t("accept") }}-->
          <!--          </v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import moment from "moment-timezone";
import { createPasswordResetUrl } from "@/services/users";
import CopyIcon from "@/components/svg/CopyIcon.vue";

export default {
  components: {
    CopyIcon,
  },
  data() {
    return {
      passwordResetDialog: false,
      snackbar: {
        showSnackbar: false,
        color: "success",
        message: "This is snackbar",
      },
    };
  },
  computed: {
    expiresDate: function () {
      let userTimezone = moment.tz.guess();
      let momentDate = moment.utc(
        this.loggedUser.expiresAt,
        "YYYYMMDD HH:mm:ss"
      );
      momentDate.tz(userTimezone);
      return momentDate.format("DD.MM.YYYY. HH:mm:ss z");
    },
    refUrl() {
      return (
        window.location.origin +
        `/registration/referrer/${this.loggedUser.refUrl}`
      );
    },
    loggedUser() {
      return this.$store.state.user;
    },
  },
  methods: {
    toggleRefCopy: function () {
      let textToCopy = document.getElementById("copyRefUrl");
      this.showSnackbar("Url kopiran!", "info");
      textToCopy.select();
      document.execCommand("copy");
    },
    resetPassword: function () {
      let self = this;
      let payload = {
        user: this.$store.state.user,
        hostName: location.origin,
      };
      createPasswordResetUrl(payload, function (data) {
        if (data.error) {
          self.showSnackbar(data.payload, "error");
        } else {
          self.showSnackbar("We sent you the reset URL email!", "success");
        }
      });
      this.passwordResetDialog = false;
    },
    editUser: function () {
      this.$router.push("/user/edit");
    },
    signOut: function () {
      this.$store.state.isLogged = false;
      localStorage.removeItem("karaoke-user-token");
      this.$router.push("/");
      this.showSnackbar("Signed out!", "success");
    },
    showSnackbar: function (message, color) {
      this.snackbar.color = color;
      this.snackbar.message = message;
      this.snackbar.showSnackbar = true;
    },
  },
};
</script>
