<template>
  <div id="payments" class="payments">
    <v-card>
      <v-text-field
        v-model="search"
        full-width
        hide-details
        placeholder="Pretraži"
        single-line
        prepend-inner-icon="search"
        class="page-search"
      ></v-text-field>
      <div class="table-wrapper">
        <v-data-table :headers="headers" :items="payments" :search="search">
          <template v-slot:item.createdOn="{ item }">
            <span>{{ new Date(item.createdOn).toLocaleString() }}</span>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>
<script>
import { getPayments } from "@/services/payments";

export default {
  computed: {},
  name: "payments",
  data() {
    return {
      payments: [],
      search: "",
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Datum",
          value: "createdOn",
        },
        {
          text: "Ime",
          value: "payer.name.given_name",
        },
        {
          text: "Prezime",
          value: "payer.name.surname",
        },
        {
          text: "Email",
          value: "payer.email_address",
        },
        {
          text: "ID Uplatioca",
          value: "payer.payer_id",
        },
        {
          text: "Država",
          value: "payer.address.country_code",
        },
        {
          text: "Uplaćeno €",
          value: "totalAmount",
        },
        {
          text: "Tip",
          value: "type",
        },
      ],
    };
  },
  methods: {},
  beforeMount() {
    let self = this;
    getPayments(function (data) {
      if (data.error) {
        self.$emit("show-snackbar", data.payload.data.status, "error");
        self.$router.push("/");
      } else {
        self.payments = data.payload.payments;
      }
    });
  },
};
</script>
<style lang="scss" scoped></style>
