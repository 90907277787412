<template>
  <div id="Users" class="users">
    <v-container>
      <div class="d-flex gap-5 justify-center align-center mt-4 mb-4">
        <button
          v-if="isLogged && isAdmin"
          @click="
            dialogNew = true;
            editing = false;
          "
          class="action-button inverted mr-2"
        >
          <plus-icon />
        </button>
        <button
          v-if="isLogged && isAdmin"
          @click="downloadCSVData"
          class="action-button inverted mr-2"
        >
          <download-icon />
        </button>
        <v-text-field
          ref="search"
          v-model="search"
          full-width
          hide-details
          placeholder="Filter"
          single-line
          class="page-search mb-0"
        ></v-text-field>
      </div>
      <v-row dense>
        <v-col v-for="(user, i) in filteredUsers" :key="i" cols="12">
          <v-lazy>
            <v-hover v-slot:default="{ hover }">
              <v-card
                :class="[user.isRegistered ? 'registered' : 'not-registered']"
              >
                <div
                  class="d-flex flex-no-wrap justify-space-between align-center"
                >
                  <div>
                    <v-card-title
                      :class="[
                        $vuetify.breakpoint.mdAndUp ? 'headline' : 'subtitle-1',
                      ]"
                      >{{ user.name }} {{ user.lastName }}</v-card-title
                    >
                    <v-card-subtitle v-text="user.email"></v-card-subtitle>
                  </div>
                  <v-avatar class="ma-3" size="64" tile>
                    <user-icon />
                  </v-avatar>
                </div>
                <div v-if="isLogged && isAdmin">
                  <v-card-actions class="gap-3">
                    <button
                      @click="editUserButton(user, i)"
                      class="action-button action-button__warning inverted sm"
                    >
                      <edit-icon />
                    </button>
                    <button
                      @click="deleteUser(user, i)"
                      class="action-button action-button__danger inverted sm"
                    >
                      <trash-icon />
                    </button>
                    <button
                      @click="resetPassword(user, i)"
                      class="action-button action-button__success inverted sm"
                    >
                      <password-icon />
                    </button>
                    <button
                      @click="manageSubscription(user, i)"
                      class="action-button action-button__primary inverted sm"
                    >
                      <subscription-icon />
                    </button>
                  </v-card-actions>
                </div>
              </v-card>
            </v-hover>
          </v-lazy>
        </v-col>
      </v-row>
      <v-dialog
        v-model="dialogNew"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        persistent
        content-class="news-dialog"
      >
        <v-card>
          <form class="custom-input-wrapper">
            <v-toolbar>
              <button
                type="button"
                @click="closeNewModal"
                class="action-button action-button__danger inverted"
              >
                <close-icon />
              </button>
              <v-toolbar-title v-if="editing"
                >Edit user {{ newUser.email }}</v-toolbar-title
              >
              <v-toolbar-title v-else>Create new user</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <button
                  v-if="editing"
                  @click="editUser"
                  type="button"
                  class="button button-primary sm"
                >
                  {{ $t("actions.save") }}
                </button>
                <button
                  v-else
                  @click="createUser"
                  type="button"
                  class="button button-primary sm"
                >
                  {{ $t("actions.create") }}
                </button>
              </v-toolbar-items>
            </v-toolbar>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="newUser.name"
                    label="Name"
                  ></v-text-field>
                  <v-text-field
                    v-model="newUser.lastName"
                    label="Last Name"
                  ></v-text-field>
                  <v-text-field
                    v-if="!editing"
                    v-model="newUser.password"
                    label="Password"
                    type="password"
                  ></v-text-field>
                  <v-text-field
                    v-model="newUser.email"
                    label="Email"
                  ></v-text-field>
                  <v-select
                    v-model="newUser.role"
                    :items="roles"
                    label="Role"
                  ></v-select>
                  <v-checkbox
                    v-model="newUser.isRegistered"
                    label="Registered"
                  ></v-checkbox>
                  <div v-if="editing">
                    <v-text-field
                      v-model="newUser.regUrl"
                      label="Registration Url"
                      disabled
                    ></v-text-field>
                    <v-menu
                      ref="menuRegUrl"
                      v-model="menuRegUrl"
                      :close-on-content-click="false"
                      :return-value.sync="newUser.regUrlValid"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="newUser.regUrlValid"
                          label="Registration valid until"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="newUser.regUrlValid"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menuRegUrl = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menuRegUrl.save(newUser.regUrlValid)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                    <v-text-field
                      v-model="newUser.refUrl"
                      label="Referral ID"
                      disabled
                    ></v-text-field>
                    <v-menu
                      ref="menuExpiresAt"
                      v-model="menuExpiresAt"
                      :close-on-content-click="false"
                      :return-value.sync="newUser.expiresAt"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      disabled
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="newUser.expiresAt"
                          label="Expires At"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="newUser.expiresAt"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="menuExpiresAt = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menuExpiresAt.save(newUser.expiresAt)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                    <v-text-field
                      v-model="newUser.resetUrl"
                      label="Reset URL"
                      disabled
                    ></v-text-field>
                    <v-menu
                      ref="menuResetUrl"
                      v-model="menuResetUrl"
                      :close-on-content-click="false"
                      :return-value.sync="newUser.resetUrlValid"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="newUser.resetUrlValid"
                          label="Reset Valid To"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="newUser.resetUrlValid"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="menuResetUrl = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.menuResetUrl.save(newUser.resetUrlValid)
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </form>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogSubscription"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        persistent
        content-class="news-dialog"
      >
        <v-card>
          <form class="custom-input-wrapper">
            <v-toolbar>
              <button
                type="button"
                @click="closeSubscriptionDialog"
                class="action-button action-button__danger inverted"
              >
                <close-icon />
              </button>
              <v-toolbar-title
                >Manage subscription for {{ newUser.email }}</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <button
                  @click="saveSubscription"
                  type="button"
                  class="button button-primary sm"
                >
                  {{ $t("actions.save") }}
                </button>
              </v-toolbar-items>
            </v-toolbar>
            <v-container>
              <v-row>
                <v-col>
                  <v-card-title>Current Subscription</v-card-title>
                  <div v-if="newUser.activeSubscription">
                    <v-text-field
                      v-model="newUser.activeSubscription.name"
                      label="Subscription Name"
                      readonly
                    ></v-text-field>
                    <v-text-field
                      v-model="newUser.activeSubscription.valueDays"
                      label="Days"
                      readonly
                    ></v-text-field>
                    <v-text-field
                      v-model="newUser.activeSubscription.price"
                      label="Price €"
                      readonly
                    ></v-text-field>
                    <v-text-field
                      v-model="newUser.activeSubscription.validUntil"
                      label="Lasts until"
                      readonly
                    ></v-text-field>
                  </div>
                  <v-text-field
                    v-model="newUser.expiresAt"
                    label="User expires"
                    readonly
                  ></v-text-field>
                  <v-card-title>New Subscription</v-card-title>
                  <v-list>
                    <v-card-title>Select from existing:</v-card-title>
                    <v-list-item-group v-model="subscription">
                      <v-list-item
                        v-for="(item, i) in subscriptions"
                        :key="i"
                        :value="item"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-text-field
                    v-model="subscription.name"
                    label="Subscription Name"
                  ></v-text-field>
                  <v-text-field
                    v-model="subscription.valueDays"
                    label="Days"
                    type="Number"
                  ></v-text-field>
                  <v-text-field
                    v-model="subscription.price"
                    label="Price €"
                    type="Number"
                  ></v-text-field>
                  <v-checkbox
                    v-model="addPayment"
                    label="Add custom payment"
                  ></v-checkbox>
                  <div v-if="addPayment">
                    <v-text-field
                      v-model="payment.status"
                      label="Status"
                      readonly
                    ></v-text-field>
                    <v-text-field
                      v-model="payment.payer.name.given_name"
                      label="Name"
                    ></v-text-field>
                    <v-text-field
                      v-model="payment.payer.name.surname"
                      label="Last Name"
                    ></v-text-field>
                    <v-text-field
                      v-model="payment.payer.email_address"
                      label="Email"
                    ></v-text-field>
                    <v-text-field
                      v-model="payment.totalAmount"
                      label="Amount €"
                      type="Number"
                    ></v-text-field>
                    <v-text-field
                      v-model="payment.type"
                      label="Type"
                      readonly
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </form>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import {
  getAdminUsers,
  createAdminUser,
  deleteAdminUser,
  editAdminUser,
  createPasswordResetUrl,
} from "@/services/users";
import {
  getSubscriptions,
  createCustomSubscription,
} from "@/services/subscriptions";
import PlusIcon from "@/components/svg/PlusIcon.vue";
import UserIcon from "@/components/svg/UserIcon.vue";
import EditIcon from "@/components/svg/EditIcon.vue";
import TrashIcon from "@/components/svg/TrashIcon.vue";
import PasswordIcon from "@/components/svg/PasswordIcon.vue";
import SubscriptionIcon from "@/components/svg/SubscriptionIcon.vue";
import CloseIcon from "@/components/svg/CloseIcon.vue";
import DownloadIcon from "@/components/svg/DownloadIcon.vue";

export default {
  components: {
    CloseIcon,
    SubscriptionIcon,
    PasswordIcon,
    TrashIcon,
    EditIcon,
    UserIcon,
    PlusIcon,
    DownloadIcon,
  },
  computed: {
    isLogged() {
      return this.$store.state.isLogged;
    },
    isAdmin() {
      return this.$store.state.user.role === "legatus";
    },
    filteredUsers() {
      const search = this.search.toLowerCase();

      if (!search) return this.users;

      if (search === this.filterReg) {
        return this.users.filter((user) => !user.isRegistered);
      }

      return this.users.filter((user) => {
        const text = user.email.toLowerCase();

        return text.indexOf(search) > -1;
      });
    },
  },
  name: "users",
  data() {
    return {
      users: [],
      subscriptions: [],
      dialogNew: false,
      editing: false,
      search: "",
      filterReg: "noreg",
      newUser: {
        name: "",
        lastName: "",
        password: "",
        email: "",
        basket: [],
        isRegistered: true,
        refUrl: null,
        regUrl: null,
        regUrlValid: null,
        resetUrl: null,
        resetUrlValid: null,
        role: "pleb",
        expiresAt: null,
        activeSubscription: {
          name: "",
          valueDays: 0,
          price: 0,
          validUntil: null,
        },
      },
      subscription: {
        name: "",
        valueDays: 0,
        price: 0,
        validUntil: null,
      },
      payment: {
        status: "COMPLETED",
        payer: {
          name: {
            given_name: "",
            surname: "",
          },
          email_address: "",
        },
        totalAmount: 0,
        type: "custom",
      },
      roles: ["pleb", "legatus", "editing"],
      menuRegUrl: false,
      menuResetUrl: false,
      menuExpiresAt: false,
      selectedUserIndex: null,
      dialogSubscription: false,
      addPayment: false,
    };
  },
  methods: {
    closeNewModal: function () {
      this.newUser = {
        name: "",
        lastName: "",
        password: "",
        email: "",
        basket: [],
        isRegistered: true,
        refUrl: null,
        regUrl: null,
        regUrlValid: null,
        resetUrl: null,
        resetUrlValid: null,
        role: "pleb",
        expiresAt: null,
        activeSubscription: {
          name: "",
          valueDays: 0,
          price: 0,
          validUntil: null,
        },
      };
      this.dialogNew = false;
    },
    closeSubscriptionDialog: function () {
      this.newUser = {
        name: "",
        lastName: "",
        password: "",
        email: "",
        basket: [],
        isRegistered: true,
        refUrl: null,
        regUrl: null,
        regUrlValid: null,
        resetUrl: null,
        resetUrlValid: null,
        role: "pleb",
        expiresAt: null,
        activeSubscription: {
          name: "",
          valueDays: 0,
          price: 0,
          validUntil: null,
        },
      };

      this.subscription = {
        name: "",
        valueDays: 0,
        price: 0,
        validUntil: null,
      };

      this.payment = {
        status: "COMPLETED",
        payer: {
          name: {
            given_name: "",
            surname: "",
          },
          email_address: "",
        },
        totalAmount: 0,
        type: "custom",
      };
      this.dialogSubscription = false;
    },
    saveSubscription: function () {
      let self = this;
      let paymentPayload = this.addPayment ? this.payment : false;
      let props = {
        user: this.newUser,
        subscription: this.subscription,
        payment: paymentPayload,
      };
      createCustomSubscription(props, function (data) {
        if (!data.error) {
          self.$emit("show-snackbar", data.payload.status, "success");
          self.users.splice(self.selectedUserIndex, 1);
          self.users.push(data.payload.user);
          self.closeSubscriptionDialog();
        } else {
          self.$emit("show-snackbar", data.payload.data.status, "error");
        }
      });
    },
    createUser: function () {
      let self = this;
      let props = {
        user: {
          name: this.newUser.name,
          lastName: this.newUser.lastName,
          password: this.newUser.password,
          email: this.newUser.email,
          role: this.newUser.role,
          isRegistered: this.newUser.isRegistered,
        },
      };
      createAdminUser(props, function (data) {
        if (!data.error) {
          self.$emit("show-snackbar", data.payload.status, "success");
          self.users.push(data.payload.user);
        } else {
          self.$emit("show-snackbar", data.payload.data.status, "error");
        }
      });
    },
    deleteUser: function (user, index) {
      let self = this;
      deleteAdminUser(user._id, function (data) {
        if (data.error) {
          self.$emit("show-snackbar", data.payload.data.status, "error");
        } else {
          self.$emit("show-snackbar", "User Deleted", "success");
          self.users.splice(index, 1);
        }
      });
    },
    editUserButton: function (item, index) {
      this.selectedUserIndex = index;
      this.newUser = item;
      this.dialogNew = true;
      this.editing = true;
    },
    manageSubscription: function (item, index) {
      this.selectedUserIndex = index;
      this.newUser = item;
      this.dialogSubscription = true;
    },
    editUser: function () {
      let self = this;
      let props = {
        user: this.newUser,
      };
      editAdminUser(this.newUser._id, props, function (data) {
        if (data.error) {
          self.$emit("show-snackbar", data.payload.data.status, "error");
        } else {
          self.$emit("show-snackbar", "User Modified", "success");
        }
      });
    },
    resetPassword: function (user) {
      let self = this;
      let payload = {
        user,
        hostName: location.origin,
      };
      createPasswordResetUrl(payload, function (data) {
        if (data.error) {
          self.$emit("show-snackbar", data.payload.data.status, "error");
        } else {
          self.$emit(
            "show-snackbar",
            `Reset password email is sent to! ${user.email}`,
            "success"
          );
        }
      });
      this.passwordResetDialog = false;
    },
    downloadCSVData: function() {
    let csv = 'email\n';
    this.users.forEach((row) => {
            csv += `${row.email}\n`;
    });
 
    const anchor = document.createElement('a');
    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    anchor.target = '_blank';
    anchor.download = 'emails.csv';
    anchor.click();
    },
  },
  beforeMount() {
    let self = this;
    getAdminUsers(function (data) {
      if (data.error) {
        self.$emit("show-snackbar", data.payload.data.status, "error");
      } else {
        self.users = data.payload.users;
      }
    });
    getSubscriptions(function (data) {
      if (data.error) {
        self.$emit("show-snackbar", data.payload.data.status, "error");
      } else {
        self.subscriptions = data.payload.subscriptions;
      }
    });
  },
};
</script>
<style lang="scss" scoped></style>
