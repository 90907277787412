<template>
  <div class="make-money">
    <div class="make-money__wrapper">
      <h1>{{ $t("make_money.description_1") }}</h1>
      <h3>{{ $t("make_money.description_2", { prize: '4.99€' }) }}</h3>
      <p>{{ $t("make_money.description_3", { prize: '4.99€' }) }}</p>
      <p>{{ $t("make_money.description_4") }}</p>
      <p>{{ $t("make_money.description_5") }}</p>
      <p>{{ $t("make_money.description_6") }}</p>
      <div class="make-money__referral">
        <label v-if="isLogged">{{ $t("my_referrer_url") }}</label>
        <div v-if="isLogged" class="make-money__referral--url">
          <input id="copyRefUrl" :value="refUrl" readonly />
          <button class="copy-btn" @click="toggleRefCopy">
            <copy-icon />
          </button>
        </div>
        <div v-if="!isLogged" class="sign-in">
          <p>{{ $t("make_money.sign-in") }}</p>
        </div>
      </div>
      <p>{{ $t("make_money.description_7", { prize: '4.99€' }) }}</p>
      <p>{{ $t("make_money.description_8") }}</p>
      <h3>{{ $t("make_money.description_9") }}</h3>
      <div>
        <p>Email: <a class="contact" href="mailto:studiodsrb@hotmail.com">studiodsrb@hotmail.com</a></p>
        <p>Viber/Whatsapp: <a class="contact" href="tel:+381643311191">+381643311191</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import CopyIcon from "@/components/svg/CopyIcon.vue";

export default {
  components: {CopyIcon},
  data() {
    return {
      snackbar: {
        showSnackbar: false,
        color: "success",
        message: "This is snackbar",
      },
    };
  },
  methods: {
    toggleRefCopy: function () {
      let textToCopy = document.getElementById("copyRefUrl");
      this.showSnackbar("Url kopiran!", "info");
      textToCopy.select();
      document.execCommand("copy");
    },
    showSnackbar: function (message, color) {
      this.snackbar.color = color;
      this.snackbar.message = message;
      this.snackbar.showSnackbar = true;
    },
  },
  computed: {
    refUrl() {
      return (
          window.location.origin +
          `/registration/referrer/${this.loggedUser.refUrl}`
      );
    },
    loggedUser() {
      return this.$store.state.user;
    },
    isLogged() {
      return this.$store.state.isLogged;
    },
  }
}
</script>
