import axios from "axios";

const apiBase = "/api/";

let getPayments = function (callback) {
  let url = apiBase + "payments";
  axios
    .get(url, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

export { getPayments };
