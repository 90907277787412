import axios from "axios";

const apiBase = "/api/";

let getUser = function (callback) {
  let url = apiBase + "user/get";
  axios
    .get(url, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

const registerUser = function (props, callback) {
  let url = apiBase + "user/register";
  axios
    .post(url, props)
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let getUserRegUrl = function (val, callback) {
  let url = apiBase + "user/get/" + val;
  axios
    .get(url)
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

const confirmUser = function (props, callback) {
  let url = apiBase + "user/confirm";
  axios
    .post(url, props)
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

const loginUser = function (props, callback) {
  let url = apiBase + "user/login";
  axios
    .post(url, props)
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

const editUser = function (props, callback) {
  let url = apiBase + "user/edit";
  axios
    .put(url, props, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

const resetPassword = function (props, callback) {
  let url = apiBase + "user/password";
  axios
    .put(url, props, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let getPasswordResetUrl = function (val, callback) {
  let url = apiBase + "user/password/get/" + val;
  axios
    .get(url)
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let createPasswordResetUrl = function (props, callback) {
  let url = apiBase + "user/password/create";
  axios
    .put(url, props)
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

const getAdminUsers = function (callback) {
  let url = apiBase + "admin/user";
  axios
    .get(url, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

const createAdminUser = function (props, callback) {
  let url = apiBase + "admin/user";
  axios
    .post(url, props, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let deleteAdminUser = function (value, callback) {
  let url = apiBase + "admin/user/" + value;
  axios
    .delete(url, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let editAdminUser = function (value, props, callback) {
  let url = apiBase + "admin/user/" + value;
  axios
    .put(url, props, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let checkUserSubscription = function (callback) {
  let url = apiBase + "user/subscription";
  axios
    .get(url, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let getUserRefUrl = function (val, callback) {
  let url = apiBase + "user/get/referrer/" + val;
  axios
    .get(url)
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let getReferrals = function (callback) {
  let url = apiBase + "admin/referrals/";
  axios
    .get(url, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let createPlaylist = function (props, callback) {
  let url = apiBase + "user/create-playlist";
  axios
    .post(url, props, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let getPlaylists = function (callback) {
  let url = apiBase + "user/get-playlists/";
  axios
    .get(url, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let deletePlaylist = function (value, callback) {
  let url = apiBase + "user/delete-playlists/" + value;
  axios
    .delete(url, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

export {
  getUser,
  registerUser,
  getUserRegUrl,
  confirmUser,
  loginUser,
  editUser,
  resetPassword,
  getPasswordResetUrl,
  createPasswordResetUrl,
  getAdminUsers,
  createAdminUser,
  deleteAdminUser,
  editAdminUser,
  checkUserSubscription,
  getUserRefUrl,
  getReferrals,
  createPlaylist,
  getPlaylists,
  deletePlaylist,
};
