import axios from "axios";

const apiBase = "/api/";

let getSongs = function (callback) {
  let url = apiBase + "songs";
  axios
    .get(url)
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let getArtists = function (callback) {
  let url = apiBase + "artists";
  axios
    .get(url)
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let getGenres = function (callback) {
  let url = apiBase + "genres";
  axios
    .get(url)
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let getArtistSongs = function (name, callback) {
  let url = apiBase + "artist/" + name;
  axios
    .get(url)
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let getSong = function (id, callback) {
  let url = apiBase + "songs/" + id;
  axios
    .get(url)
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let getSongUrl = function (songId, callback) {
  let url = apiBase + "getsongurl/" + songId;
  axios
    .get(url, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let getVideoUrl = function (songId, callback) {
  let url = apiBase + "getvideourl/" + songId;
  axios
    .get(url, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let getGenreArtists = function (genre, callback) {
  let url = apiBase + "genre/" + genre;
  axios
    .get(url)
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let searchSongs = function (value, callback) {
  let url = apiBase + "search/" + value;
  axios
    .get(url)
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let deleteSong = function (value, callback) {
  let url = apiBase + "songs/" + value;
  axios
    .delete(url, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let editSong = function (value, props, callback) {
  let url = apiBase + "songs/" + value;
  axios
    .put(url, props, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let uploadImage = function (props, callback) {
  let url = apiBase + "upload/image";
  axios
    .post(url, props, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let uploadNewSong = function (props, callback) {
  let url = apiBase + "upload/new/song";
  axios
    .post(url, props, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let uploadSong = function (props, callback) {
  let url = apiBase + "upload/song";
  axios
    .post(url, props, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let uploadVideo = function (props, callback) {
  let url = apiBase + "upload/video";
  axios
    .post(url, props, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

let processSong = function (props, callback) {
  let url = apiBase + "song-manipulate";
  axios
    .post(url, props, {
      headers: {
        Authorization: localStorage.getItem("karaoke-user-token"),
      },
    })
    .then(function (res) {
      callback({
        error: false,
        payload: res.data,
      });
    })
    .catch(function (err) {
      let data = null;
      if (err.response) {
        data = err.response;
      } else {
        data = {
          message: err,
        };
      }
      callback({
        error: true,
        payload: data,
      });
    });
};

export {
  getSongs,
  getArtists,
  getGenres,
  getArtistSongs,
  getSong,
  getSongUrl,
  getGenreArtists,
  searchSongs,
  deleteSong,
  editSong,
  uploadImage,
  uploadNewSong,
  uploadSong,
  processSong,
  uploadVideo,
  getVideoUrl,
};
