<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card class="login-form text-xs-center">
          <div class="display-1 mb-3">
            <v-card-text>
              <div class="subheading">
                <v-card-title>{{ $t("reset_password") }}</v-card-title>
              </div>
              <v-form ref="form" v-model="isValid">
                <v-text-field
                  v-model="user.email"
                  light="light"
                  prepend-icon="email"
                  label="Email"
                  type="email"
                  disabled
                ></v-text-field>
                <v-text-field
                  v-model="user.password"
                  light="light"
                  prepend-icon="lock"
                  label="Nova šifra"
                  type="password"
                  :rules="passwordRules"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="repeatPassword"
                  light="light"
                  prepend-icon="lock"
                  label="Potvrdite Novu Šifru"
                  type="password"
                  :rules="repeatPasswordRules"
                  required
                ></v-text-field>
                <v-btn
                  class="mb-2"
                  @click="confirm"
                  @click.prevent
                  block="block"
                  type="submit"
                  color="primary"
                  >{{ $t("accept") }}</v-btn
                >
                <v-btn
                  class="mb-2"
                  @click="resetData"
                  @click.prevent
                  block="block"
                  type="submit"
                  color="error"
                  >{{ $t("decline") }}</v-btn
                >
              </v-form>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { getPasswordResetUrl, resetPassword } from "@/services/users";

export default {
  props: {
    resetUrl: {
      type: String,
      default: null,
    },
  },
  computed: {
    repeatPasswordRules() {
      return [
        () =>
          this.user.password === this.repeatPassword ||
          "Šifre se ne podudaraju",
        (v) => !!v || "Molim Vas ponovite šifru",
      ];
    },
  },
  data: () => ({
    user: {},
    repeatPassword: "",
    isValid: true,
    passwordRules: [
      (v) => !!v || "Šifra je neophodna",
      (v) =>
        /^(?=[a-zA-Z0-9#@$?\!]{5,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).*/.test(
          v
        ) ||
        "Šifra mora da sadrži jedno veliko jedno malo slovo i bar jedan broj",
    ],
  }),
  methods: {
    confirm: function () {
      if (this.isValid) {
        let props = {
          user: this.user,
          resetUrl: this.resetUrl,
        };
        let self = this;
        resetPassword(props, function (data) {
          if (!data.error) {
            self.$emit("show-snackbar", data.payload.status, "success");
            if (!self.$store.state.isLogged) {
              localStorage.removeItem("karaoke-user-token");
              self.$router.push({ path: "/", query: { showLogin: true } });
            }
          } else {
            self.$emit("show-snackbar", data.payload.data.status, "error");
          }
        });
      } else {
        this.$refs.form.validate();
      }
    },
    resetData: function () {
      this.user.password = "";
      this.repeatPassword = "";
      this.$refs.form.resetValidation();
    },
  },
  beforeMount() {
    let self = this;
    getPasswordResetUrl(this.resetUrl, function (data) {
      if (data.error) {
        self.$emit("show-snackbar", data.payload.data.status, "error");
        self.$router.push("/404");
      } else {
        self.user = data.payload;
      }
    });
  },
};
</script>
<style lang="scss" scoped></style>
