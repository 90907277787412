<template>
  <div id="Song" class="song">
    <v-card
      ><v-card-title class="justify-center"
        >{{ song.artist }} - {{ song.songName }} </v-card-title
      ><v-card-text>
        <pre class="text-center">{{ song.textContent }}</pre>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { getSong, getSongUrl } from "@/services/songs";

export default {
  name: "song",
  metaInfo() {
    return {
      title: `Karaoke Studio D - ${this.song.artist} - ${this.song.songName}`,
      meta: [
        {
          name: "description",
          content:
            "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno!",
        },
        {
          name: "twitter:card",
          content: `${this.song.artist} - ${this.song.songName}`,
        },
        {
          name: "twitter:site",
          content: "@matricestudiod",
        },
        {
          name: "twitter:title",
          content: `Karaoke Studio D - ${this.song.artist} - ${this.song.songName}`,
        },
        {
          name: "twitter:description",
          content:
            "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno!",
        },
        {
          name: "twitter:creator",
          content: "@matricestudiod",
        },
        {
          name: "twitter:image",
          content: `${window.location.origin}/api/stream/${this.song.image}`,
        },
        {
          property: "og:title",
          content: `Karaoke Studio D - ${this.song.artist.replace(/\s/g, '-')}-${this.song.songName.replace(/\s/g, '-')}`,
        },
        {
          property: "og:url",
          content: this.socialUrl,
        },
        {
          property: "og:image",
          content: `${window.location.origin}${this.imgPath}`,
        },
        {
          property: "og:description",
          content:
            "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno",
        },
        { property: "og:site_name", content: "Karaoke" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  props: {
    name: String,
    songName: String,
    id: String,
  },
  data() {
    return {
      song: {},
      socialUrl: window.location.origin + encodeURI(this.$route.path),
      socialTags: "",
      socialTitle: "",
      socialDescription: "",
      shareActive: false,
    };
  },
  watch: {
    $route(to, from) {
      this.loadSong();
    },
  },
  methods: {
    toggleCopy: function () {
      let textToCopy = this.$refs.copyUrl.$el.querySelector("input");
      textToCopy.select();
      document.execCommand("copy");
    },
    loadSong: function () {
      let self = this;
      getSong(this.id, function (data) {
        if (data.error) {
          self.$emit("show-snackbar", data.payload.data.status, "error");
          self.$router.push("/");
        } else {
          self.song = data.payload;
          self.socialTitle = self.song.artist + "-" + self.song.songName;
          self.socialDescription = self.song.textContent;
          self.socialTags = self.song.tags.replace(/ /g, ",").replace(/#/g, "");
          getSongUrl(self.song._id, function (data) {
            if (data.error) {
              self.$emit("show-snackbar", data.payload.data.status, "error");
              self.$router.push("/");
            } else {
              self.$emit("song-selected", {
                songUrl: data.payload.url,
                song: self.song,
                limit: data.payload.limit,
                playlistId: null,
              });
            }
          });
        }
      });
    },
  },
  beforeMount() {
    this.loadSong();
  },
};
</script>
<style lang="scss" scoped>
pre {
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  font-family: Roboto, sans-serif;
  pointer-events: auto;
  box-sizing: inherit;
  background-repeat: no-repeat;
  margin: 0;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  width: 100%;
  color: rgba(0, 0, 0, 0.8);
  padding: 0 24px 20px;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
</style>
