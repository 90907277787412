<template>
  <v-container fluid>
    <div class="user-edit">
      <v-row align="center" justify="center">
        <v-col>
          <v-card class="login-form text-xs-center">
            <div class="display-1 mb-3">
              <v-card-text>
                <h1 class="page-title">Edit User</h1>
                <v-form
                  ref="form"
                  v-model="isValid"
                  class="custom-input-wrapper"
                >
                  <v-text-field
                    v-model="user.name"
                    label="Ime"
                    :rules="requiredRules"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="user.lastName"
                    label="Prezime"
                    :rules="requiredRules"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="user.email"
                    label="Email"
                    type="email"
                    disabled
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    label="Šifra"
                    type="password"
                    :rules="requiredRules"
                    required
                  ></v-text-field>
                  <div class="buttons-wrapper">
                    <button
                      class="button button-danger"
                      @click="resetData"
                      @click.prevent
                    >
                      Odustani
                    </button>
                    <button
                      class="button button-primary"
                      @click="save"
                      @click.prevent
                      type="submit"
                    >
                      Potvrdi
                    </button>
                  </div>
                </v-form>
              </v-card-text>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import { editUser } from "@/services/users";

export default {
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  data: () => ({
    isValid: true,
    requiredRules: [(v) => !!v || "Ovo polje je neophodno"],
    userRes: {},
    password: "",
  }),
  methods: {
    save: function () {
      if (this.isValid) {
        this.user.password = this.password;
        let props = {
          user: this.user,
        };
        let self = this;
        editUser(props, function (data) {
          if (!data.error) {
            self.$emit("show-snackbar", "Izmene su sačuvane!", "success");
            Object.assign(self.$data.userRes, self.$store.state.user);
          } else {
            self.resetData();
            self.$emit("show-snackbar", data.payload.data.status, "error");
          }
        });
      } else {
        this.$refs.form.validate();
      }
    },
    resetData: function () {
      this.password = "";
      this.$refs.form.resetValidation();
      Object.assign(this.$store.state.user, this.$data.userRes);
    },
  },
  created() {
    Object.assign(this.$data.userRes, this.$store.state.user);
  },
  beforeDestroy() {
    Object.assign(this.$store.state.user, this.$data.userRes);
  },
};
</script>
<style lang="scss" scoped></style>
