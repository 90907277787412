<template>
  <div id="About" class="news">
    <v-container>
      <button
        v-if="isLogged && isAdmin"
        @click="
          dialogNew = true;
          editing = false;
        "
        class="action-button inverted mb-3"
      >
        <plus-icon />
      </button>
      <v-row dense>
        <v-col v-for="(item, i) in news" :key="i" cols="12">
          <v-lazy>
            div
            <v-card>
              <v-card-title
                :class="[
                  $vuetify.breakpoint.mdAndUp ? 'headline' : 'subtitle-1',
                ]"
                v-text="item.headline"
              ></v-card-title>
              <v-row justify="center" align="center">
                <v-img
                  position="center"
                  :max-width="item.image.maxWidth"
                  :max-height="item.image.maxHeight"
                  v-if="item.image.src"
                  :src="'/api/stream/' + item.image.src"
                ></v-img>
              </v-row>
              <v-row justify="center" align="center">
                <video
                  v-if="item.video.src"
                  controls
                  :style="
                    'max-width:' +
                    item.video.maxWidth +
                    '; max-height:' +
                    item.video.maxHeight +
                    ';'
                  "
                  :src="'/api/stream/' + item.video.src"
                ></video>
              </v-row>
              <v-card-text
                :class="[
                  $vuetify.breakpoint.mdAndUp ? 'subtitle-1' : 'subtitle-2',
                ]"
                v-html="item.textContent"
              ></v-card-text>
              <div v-if="isLogged && isAdmin">
                <v-card-actions>
                  <button
                    @click="editNewsButton(item, i)"
                    class="action-button action-button__success sm"
                  >
                    <edit-icon />
                  </button>
                  <button
                    @click="deleteNewsButton(item, i)"
                    class="action-button action-button__danger sm"
                  >
                    <trash-icon />
                  </button>
                </v-card-actions>
              </div>
            </v-card>
          </v-lazy>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialogNew"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      content-class="news-dialog"
    >
      <v-card>
        <form class="custom-input-wrapper">
          <v-toolbar>
            <button
              type="button"
              @click="closeNewModal"
              class="action-button action-button__danger inverted"
            >
              <close-icon />
            </button>
            <v-toolbar-title v-if="editing">{{
              $t("edit.news")
            }}</v-toolbar-title>
            <v-toolbar-title v-else>{{
              $t("create.new.news")
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <button
                v-if="editing"
                @click="editNews"
                type="button"
                class="button button-primary sm"
              >
                {{ $t("actions.save") }}
              </button>
              <button
                v-else
                @click="createNews"
                type="button"
                class="button button-primary sm"
              >
                {{ $t("actions.create") }}
              </button>
            </v-toolbar-items>
          </v-toolbar>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="newNews.name"
                  label="Name"
                ></v-text-field>
                <v-text-field
                  v-model="newNews.headline"
                  label="Headline"
                ></v-text-field>
                <v-textarea
                  rows="8"
                  v-model="newNews.textContent"
                  label="Content"
                ></v-textarea>
                <v-text-field
                  v-model="newNews.image.maxHeight"
                  label="Height"
                ></v-text-field>
                <v-text-field
                  v-model="newNews.image.maxWidth"
                  label="Width"
                ></v-text-field>
                <v-row justify="center" align="center">
                  <v-img
                    v-if="this.newImage.src || this.newNews.image.src"
                    position="center"
                    :max-height="newNews.image.maxHeight"
                    :max-width="newNews.image.maxWidth"
                    :src="selectedNewsImage"
                  ></v-img>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="newNews.image.src"
                      label="Image"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="6">
                    <v-file-input
                      accept="image/png, image/jpeg, image/bmp"
                      placeholder="Upload new image"
                      v-on:change="imageChanged($event)"
                      label="Image"
                      prepend-icon="mdi-camera"
                    >
                      <edit-icon />
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card>
    </v-dialog>
    <v-overlay :value="imageUploading" z-index="999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { getNews, createNews, editNews, deleteNews } from "@/services/news";
import { uploadImage } from "@/services/songs";
import PlusIcon from "@/components/svg/PlusIcon.vue";
import EditIcon from "@/components/svg/EditIcon.vue";
import TrashIcon from "@/components/svg/TrashIcon.vue";
import CloseIcon from "@/components/svg/CloseIcon.vue";

export default {
  name: "about",
  components: {
    PlusIcon,
    EditIcon,
    TrashIcon,
    CloseIcon,
  },
  metaInfo() {
    return {
      title: "Karaoke Studio D - O nama",
      meta: [
        {
          name: "description",
          content:
            "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno!",
        },
        {
          name: "twitter:card",
          content: "Karaoke",
        },
        {
          name: "twitter:site",
          content: "@matricestudiod",
        },
        {
          name: "twitter:title",
          content: "Karaoke Studio D - O nama",
        },
        {
          name: "twitter:description",
          content:
            "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno!",
        },
        {
          name: "twitter:creator",
          content: "@matricestudiod",
        },
        {
          name: "twitter:image",
          content: "https://karaokesrbija.com/api/stream/LOGO.jpg",
        },
        {
          property: "og:title",
          content: "Karaoke Studio D - O nama",
        },
        {
          property: "og:url",
          content: `${window.location.origin}/about`,
        },
        {
          property: "og:image",
          content: "https://karaokesrbija.com/api/stream/LOGO.jpg",
        },
        {
          property: "og:description",
          content:
            "✔ Slusaj ✔ Pevaj ✔ Pretplati se, Preko 10000 matrica na jednom mestu, samo 9.99€ mesečno",
        },
        { property: "og:site_name", content: "Karaoke" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  data() {
    return {
      news: [],
      imageUploading: false,
      editing: false,
      selectedNewsIndex: null,
      newNews: {
        name: "",
        headline: "",
        textContent: "",
        image: {
          src: "",
          maxWidth: "100%",
          maxHeight: "100%",
        },
        video: {
          src: "",
          maxWidth: "100%",
          maxHeight: "100%",
        },
      },
      newImage: {
        src: null,
      },
      dialogNew: false,
    };
  },
  computed: {
    isLogged() {
      return this.$store.state.isLogged;
    },
    isAdmin() {
      return this.$store.state.user.role === "legatus";
    },
    selectedNewsImage() {
      return this.newImage.src || "/api/stream/" + this.newNews.image.src;
    },
  },
  methods: {
    imageChanged: function (e) {
      if (e) {
        this.newImage = e;
        this.newNews.image.src = this.newImage.name.replace(/ /g, "");
        this.newImage.src = URL.createObjectURL(e);
      } else {
        this.newImage = {
          src: null,
        };
      }
    },
    closeNewModal: function () {
      this.newNews = {
        name: "",
        headline: "",
        textContent: "",
        image: {
          src: "",
          maxWidth: "100%",
          maxHeight: "100%",
        },
        video: {
          src: "",
          maxWidth: "100%",
          maxHeight: "100%",
        },
      };
      this.dialogNew = false;
    },
    createNews: function () {
      this.imageUploading = true;
      let self = this;
      let formData = new FormData();
      let props = {
        news: this.newNews,
      };
      if (this.newImage.src) {
        formData.append("file", this.newImage);
        formData.append("imageName", this.newNews.image.src);
        uploadImage(formData, function (data) {
          if (data.error) {
            self.$emit("show-snackbar", data.payload.data.status, "error");
            self.imageUploading = false;
          } else {
            createNews(props, function (data) {
              if (!data.error) {
                self.$emit("show-snackbar", data.payload.status, "success");
                self.news.push(data.payload.news);
                self.imageUploading = false;
              } else {
                self.$emit("show-snackbar", data.payload.data.status, "error");
                self.imageUploading = false;
              }
            });
          }
        });
      } else {
        createNews(props, function (data) {
          if (!data.error) {
            self.$emit("show-snackbar", data.payload.status, "success");
            self.news.push(data.payload.news);
            self.imageUploading = false;
          } else {
            self.$emit("show-snackbar", data.payload.data.status, "error");
            self.imageUploading = false;
          }
        });
      }
    },
    editNewsButton: function (item, index) {
      this.selectedNewsIndex = index;
      this.newNews = item;
      this.dialogNew = true;
      this.editing = true;
    },
    deleteNewsButton: function (item, index) {
      let self = this;
      deleteNews(item._id, function (data) {
        if (data.error) {
          self.$emit("show-snackbar", data.payload.data.status, "error");
        } else {
          self.$emit("show-snackbar", "News Deleted", "success");
          self.news.splice(index, 1);
        }
      });
    },
    editNews: function () {
      this.imageUploading = true;
      let self = this;
      let props = {
        news: this.newNews,
      };
      let formData = new FormData();
      if (this.newImage.src) {
        formData.append("file", this.newImage);
        formData.append("imageName", this.newNews.image.src);
        uploadImage(formData, function (data) {
          if (data.error) {
            self.$emit("show-snackbar", data.payload.data.status, "error");
            self.imageUploading = false;
          } else {
            editNews(props.news._id, props, function (data) {
              if (!data.error) {
                self.$emit("show-snackbar", data.payload.status, "success");
                self.imageUploading = false;
              } else {
                self.$emit("show-snackbar", data.payload.data.status, "error");
                self.imageUploading = false;
              }
            });
          }
        });
      } else {
        editNews(props.news._id, props, function (data) {
          if (!data.error) {
            self.$emit("show-snackbar", data.payload.status, "success");
            self.imageUploading = false;
          } else {
            self.$emit("show-snackbar", data.payload.data.status, "error");
            self.imageUploading = false;
          }
        });
      }
    },
  },
  beforeMount() {
    let self = this;
    getNews(function (data) {
      if (data.error) {
        self.$emit("show-snackbar", data.payload.data.status, "error");
        self.$router.push("/");
      } else {
        self.news = data.payload.news;
      }
    });
  },
};
</script>
<style lang="scss" scoped></style>
