<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card class="login-form text-xs-center">
          <div class="display-1 mb-3">
            <v-card-text>
              <div class="subheading">
                <v-card-title>{{ $t("request_new_password") }}</v-card-title>
              </div>
              <v-form ref="form" v-model="isValid">
                <v-text-field
                  v-model="user.email"
                  light="light"
                  prepend-icon="email"
                  label="Email"
                  type="email"
                  :rules="emailRules"
                  required
                ></v-text-field>
                <v-btn
                  class="mb-2"
                  @click="confirm"
                  @click.prevent
                  block="block"
                  type="submit"
                  color="primary"
                  >Potvrdi</v-btn
                >
                <v-btn
                  class="mb-2"
                  @click="resetData"
                  @click.prevent
                  block="block"
                  type="submit"
                  color="error"
                  >Odustani</v-btn
                >
              </v-form>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="passwordResetDialog" max-width="300">
      <v-card>
        <v-card-title class="headline">{{ $t("are_you_sure") }}</v-card-title>

        <v-card-text> {{ $t("we_will_send_email_link_reset") }} </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" text @click="passwordResetDialog = false">
            {{ $t("accept") }}
          </v-btn>

          <v-btn color="primary" text @click="resetPassword">
            {{ $t("accept") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { createPasswordResetUrl } from "@/services/users";

export default {
  data: () => ({
    user: {},
    isValid: true,
    emailRules: [
      (v) => !!v || "Email polje je neophodno",
      (v) => /.+@.+\..+/.test(v) || "Email mora biti validan",
    ],
    passwordResetDialog: false,
  }),
  methods: {
    confirm: function () {
      if (this.isValid) {
        this.passwordResetDialog = true;
      } else {
        this.$refs.form.validate();
      }
    },
    resetData: function () {
      this.user.email = "";
      this.$refs.form.resetValidation();
    },
    resetPassword: function () {
      let self = this;
      let payload = {
        user: this.user,
        hostName: location.origin,
      };
      createPasswordResetUrl(payload, function (data) {
        if (data.error) {
          self.$emit("show-snackbar", data.payload.data.status, "error");
        } else {
          self.$emit(
            "show-snackbar",
            "Poslali smo Vam link za reset na email!",
            "success"
          );
        }
      });
      this.passwordResetDialog = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
