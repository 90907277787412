<template>
  <div id="playlist">
    <h1>{{ playlist[0].playlistName }}</h1>
    <v-row dense>
      <p v-for="song in playlist.songs" :key="song">
        {{ song.name }}
      </p>
      <v-col v-for="(song, i) in playlist[0].songs" :key="i" cols="12">
        <v-lazy>
          <v-hover v-slot:default="{ hover }">
            <v-card :elevation="hover ? 16 : 2">
              <div
                class="d-flex flex-no-wrap justify-space-between"
                v-on:click="selectSong(song, i)"
              >
                <v-avatar v-if="song.image" class="ma-3" size="30" tile>
                  <v-img :src="'/api/stream/' + song.image"></v-img>
                </v-avatar>
                <div>
                  <v-card-subtitle
                    >{{ song.artist }} - {{ song.songName }}</v-card-subtitle
                  >
                </div>
              </div>
              <v-btn
                class="mx-2"
                fab
                dark
                bottom
                right
                x-small
                absolute
                color="deep-orange darken-4"
                @click.native="removeSong(song, i)"
              >
                <v-icon dark>mdi-minus</v-icon>
              </v-btn>
            </v-card>
          </v-hover>
        </v-lazy>
      </v-col>
    </v-row>
  </div>
</template>
<script>
// import { getSong, getSongUrl } from "@/services/songs";
import { createPlaylist, getPlaylists } from "@/services/users";
import {getSong, getSongUrl} from "@/services/songs";

export default {
  computed: {
    isLogged() {
      return this.$store.state.isLogged;
    },
    playlist() {
      return this.playlists.filter(
        (el) => el.playlistName === this.$route.params.name
      );
    },
  },
  name: "single_playlist",
  data() {
    return {
      playlists: []
    };
  },
  methods: {
    selectSong: function (song, index) {
      let self = this;
      getSong(song._id, function (data) {
        if (data.error) {
          self.$emit("show-snackbar", data.payload.data.status, "error");
          self.$router.push("/");
        } else {
          self.song = data.payload;
          self.socialTitle = self.song.artist + "-" + self.song.songName;
          self.socialDescription = self.song.textContent;
          self.socialTags = self.song.tags.replace(/ /g, ",").replace(/#/g, "");
          getSongUrl(self.song._id, function (data) {
            if (data.error) {
              self.$emit("show-snackbar", data.payload.data.status, "error");
              self.$router.push("/");
            } else {
              self.$emit("song-selected", {
                songUrl: data.payload.url,
                song: self.song,
                limit: data.payload.limit,
                playlistId: index,
              });
            }
          });
        }
      });
    },
    removeSong: function (song, index) {
      this.playlist[0].songs.splice(index, 1);
    },
      addSong: function (song) {
      this.playlist.songs.push(song);
    },
    addPlaylist: function (playlist) {
      this.playlist = playlist;
    },
    savePlaylist: function () {
      let self = this;
      let props = {
        playlist: this.playlist,
      };
      createPlaylist(props, function (data) {
        if (!data.error) {
          self.$emit("show-snackbar", data.payload.status, "success");
        } else {
          self.$emit("show-snackbar", data.payload.data.status, "error");
        }
      });
    }
  },
  beforeMount() {
    let self = this;
    getPlaylists(function (data) {
      if (data.error) {
        self.$emit("show-snackbar", data.payload.data.status, "error");
      } else {
        self.playlists = data.payload.playlists;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
/deep/ .centered-input input {
  text-align: center;
}
</style>
