<template>
  <div>
    <v-container fluid class="player" :style="hidePlayer ? 'display: none;' : 'display: block;'">
      <v-row align="start" justify="start" v-if="!processing">
        <v-col v-show="showIcon" cols="1">
          <v-avatar size="70">
            <v-img :src="'/api/stream/' + this.song.image"></v-img>
          </v-avatar>
        </v-col>
        <v-col cols="1">
          <v-icon v-on:click.prevent="stop" large>mdi-stop-circle-outline</v-icon>
        </v-col>
        <v-col cols="1">
          <v-icon v-on:click.prevent="playing = !playing" v-if="!playing" large
            >mdi-play-circle-outline</v-icon
          >
          <v-icon v-on:click.prevent="playing = !playing" v-else large
            >mdi-pause-circle-outline</v-icon
          >
        </v-col>
        <v-col cols="5">
          <v-row>
            <v-col>
              <v-progress-linear
                v-on:click="seek"
                :value="this.percentComplete"
                color="black"
              ></v-progress-linear>
            </v-col>
          </v-row>
          <v-row align="start" justify="space-between">
            <v-col cols="1">
              <span
                :class="[$vuetify.breakpoint.mdAndUp ? 'subtitle-2' : 'caption']"
              >
                {{ this.currentSeconds | convertTimeHHMMSS }}
              </span>
            </v-col>
            <v-col v-show="showMobile" cols="9">
              <span
                :class="[$vuetify.breakpoint.mdAndUp ? 'subtitle-2' : 'caption']"
              >
                {{ this.song.artist }} - {{ this.song.songName }}
              </span>
            </v-col>
            <v-col cols="1">
              <span
                :class="[$vuetify.breakpoint.mdAndUp ? 'subtitle-2' : 'caption']"
              >
                {{ this.durationSeconds | convertTimeHHMMSS }}
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="1">
          <v-menu top offset-y open-on-hover>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" v-on:click.prevent="mute" v-if="!muted"
                >volume_up</v-icon
              >
              <v-icon v-on="on" v-on:click.prevent="mute" v-else
                >volume_mute</v-icon
              >
            </template>
            <v-slider
              vertical
              v-model="media"
              min="0"
              max="100"
              v-model.lazy.number="volume"
            ></v-slider>
          </v-menu>
        </v-col>
        <v-col cols="1" class="custom-input-wrapper">
          <v-select
            v-model="modifications.pitch"
            :items="items"
            menu-props="auto"
            label="Pitch"
            :disabled="!isLogged"
            v-on:change="modifySong"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-icon
            :disabled="!isLogged || !song.videoName"
            :class="song.videoName ? 'green--text' : ''"
            v-on:click.prevent="videoToggle"
          >
            mdi-monitor
          </v-icon>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" v-else>
        <v-col class="subtitle-1 text-center" cols="12">
          {{ $t("request_is_processing") }}
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="red darken-3"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
          {{videoFile}}
        </v-col>
      </v-row>
      <audio
        ref="audiofile"
        :src="file"
        preload="auto"
        style="display: none"
      ></audio>
    </v-container>
    <div
        class="video-wrapper"
      :style="hideVideo ? 'display: none' : 'display: block'"
    >
      <video
          v-if="showVideo"
          ref="videoPlayer"
          :src="videoFile"
          preload="auto"
          style="width: 300px;"
          controlsList="nodownload"
          controls
      ></video>
      <button
        class="close-video"
        type="button"
        @click="stopVideo()"
      >
        <close-icon />
      </button>
    </div>
  </div>
</template>
<script>
import { getSongUrl, processSong, getVideoUrl } from "@/services/songs";
import CloseIcon from "@/components/svg/CloseIcon.vue";

export default {
  components: {
    CloseIcon
  },
  props: {
    file: {
      type: String,
      default: null,
    },
    song: {},
    autoPlay: {
      type: Boolean,
      default: false,
    },
    limit: {
      default: false,
    },
    playlistId: {
      default: null,
    },
  },
  data: () => ({
    audio: undefined,
    video: undefined,
    videoFile: null,
    currentSeconds: 0,
    durationSeconds: 0,
    bufferedSeconds: 0,
    loaded: false,
    videoLoaded: false,
    playing: false,
    videoPlaying: false,
    previousVolume: 35,
    showVolume: false,
    volume: 100,
    modifications: {
      pitch: "0",
    },
    items: [
      { text: "-6", value: "-600" },
      { text: "-5", value: "-500" },
      { text: "-4", value: "-400" },
      { text: "-3", value: "-300" },
      { text: "-2", value: "-200" },
      { text: "-1", value: "-100" },
      { text: "0", value: "0" },
      { text: "1", value: "100" },
      { text: "2", value: "200" },
      { text: "3", value: "300" },
      { text: "4", value: "400" },
      { text: "5", value: "500" },
      { text: "6", value: "600" },
    ],
    processing: false,
    showVideo: true,
    hideVideo: true,
    hidePlayer: false
  }),
  computed: {
    isLogged() {
      return this.$store.state.isLogged;
    },
    isAdmin() {
      return this.$store.state.user.role === "legatus";
    },
    showIcon() {
      return !!this.$vuetify.breakpoint.mdAndUp;
    },
    showMobile() {
      return !!this.$vuetify.breakpoint.smAndUp;
    },
    muted() {
      return this.volume / 100 === 0;
    },
    percentComplete() {
      return parseInt((this.currentSeconds / this.durationSeconds) * 100);
    },
    percentBuffered() {
      return parseInt((this.bufferedSeconds / this.durationSeconds) * 100);
    },
  },
  filters: {
    convertTimeHHMMSS(val) {
      let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);

      return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
    },
  },
  watch: {
    playing(value) {
      if (value) {
        return this.audio.play();
      }
      this.audio.pause();
    },
    videoPlaying(value) {
      if (value) {
        return this.video.play();
      }
      this.video.pause();
    },
    currentSeconds(value) {
      if (this.limit) {
        if (this.currentSeconds > this.limit) {
          this.stop();
        }
      }
      if (this.currentSeconds >= this.durationSeconds) {
        this.$emit("song-finished-event", {
          song: this.song,
          index: this.playlistId,
        });
      }
    },
    volume(value) {
      this.audio.volume = this.volume / 100;
    },
    $route: function (value) {
      if (value.path.includes('videos')) {
        this.stop();
        this.stopVideo();
        this.hideVideo = true
        this.file = null,
        this.hidePlayer = true
      } else {
        this.hidePlayer = false
      }
    },
  },
  methods: {
    load: function () {
      if (this.audio.readyState >= 2) {
        this.loaded = true;
        if (this.limit) {
          this.durationSeconds = parseInt(this.limit);
        } else {
          this.durationSeconds = parseInt(this.audio.duration);
        }
        this.$emit("song-loaded", {});
      } else {
        throw new Error("Failed to load sound file.");
      }
    },
    play: function () {
      this.playing = true;
      this.audio.play();
    },
    playVideo: function () {
      this.video.play();
    },
    modifySong: function () {
      let self = this;
      this.processing = true;
      this.stop();
      if (this.pitch !== "0" && this.modifications.pitch === "0") {
        getSongUrl(self.song._id, function (data) {
          if (data.error) {
            self.$emit("show-snackbar", data.payload.data.status, "error");
            self.processing = false;
          } else {
            self.processing = false;
            self.audio.src = data.payload.url;
          }
        });
      } else if (this.pitch !== "0") {
        let props = {
          song: this.song,
          pitch: this.modifications.pitch,
        };
        processSong(props, function (data) {
          if (data.error) {
            self.$emit("show-snackbar", data.payload.data.status, "error");
            self.processing = false;
          } else {
            self.processing = false;
            self.audio.src = data.payload.url;
          }
        });
      }
    },
    mute: function () {
      if (this.muted) {
        return (this.volume = this.previousVolume);
      }

      this.previousVolume = this.volume;
      this.volume = 0;
    },
    seek: function (e) {
      if (!this.loaded) return;

      const el = e.target.getBoundingClientRect();
      const seekPos = (e.clientX - el.left) / el.width;

      if (this.limit) {
        this.audio.currentTime = parseInt(this.limit * seekPos);
      } else {
        this.audio.currentTime = parseInt(this.audio.duration * seekPos);
      }
    },
    stop: function () {
      this.playing = false;
      this.audio.currentTime = 0;
    },
    stopVideo: function () {
      this.videoPlaying = false;
      this.video.currentTime = 0;
      this.hideVideo = true
    },
    update: function (e) {
      this.currentSeconds = parseInt(this.audio.currentTime);
      this.bufferedSeconds =
        this.playing && this.audio.currentTime > 0
          ? this.audio.buffered.end(0)
          : 0;
    },
    textToggle: function () {
      this.$emit("text-toggle", {});
    },
    toggleFullScreen() {
      const video = this.$refs.videoPlayer;
      if (video !== null) {
        if (document.fullscreenElement === null) {
          video.requestFullscreen();
        } else {
          document.exitFullscreen();
        }
      }
    },
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { // For Safari
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) { // For Firefox
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) { // For IE
        document.msExitFullscreen();
      }
    },
    onLeavePIP() {
      const video = this.$refs.videoPlayer;
      if (document.pictureInPictureElement === video) {
        document.exitPictureInPicture();
      }
    },
    turnOffVideo() {
      const video = this.$refs.videoPlayer;
      video.src = '';
      this.onLeavePIP();
    },
    videoToggle: function () {
      this.stop();
      this.hideVideo = false;
      if (this.videoPlaying) {
        this.stopVideo();
        this.turnOffVideo()
        const self = this;
        getVideoUrl(self.song._id, function (data) {
          if (data.error) {
            self.$emit("show-snackbar", data.payload.data.status, "error");
          } else {
            self.video.src = data.payload.url;
            if (self.video.requestFullscreen) {
              self.video.requestFullscreen();
            } else if (self.video.mozRequestFullScreen) {
              self.video.mozRequestFullScreen();
            } else if (self.video.webkitRequestFullscreen) {
              self.video.webkitRequestFullscreen();
            } else if (self.video.msRequestFullscreen) {
              self.video.msRequestFullscreen();
            }
            self.video.play();
          }
        });
      } else {
        const self = this;
        getVideoUrl(self.song._id, function (data) {
          if (data.error) {
            self.$emit("show-snackbar", data.payload.data.status, "error");
          } else {
            self.video.src = data.payload.url;
            if (self.video.requestFullscreen) {
              self.video.requestFullscreen();
            } else if (self.video.mozRequestFullScreen) {
              self.video.mozRequestFullScreen();
            } else if (self.video.webkitRequestFullscreen) {
              self.video.webkitRequestFullscreen();
            } else if (self.video.msRequestFullscreen) {
              self.video.msRequestFullscreen();
            }
            self.video.play();
          }
        });
      }
    },
  },
  mounted() {
    this.audio = this.$el.querySelectorAll("audio")[0];
    this.video = this.$el.querySelectorAll("video")[0];
    this.video.disablePictureInPicture = true;
    this.audio.addEventListener("timeupdate", this.update);
    this.audio.addEventListener("loadeddata", this.load);
    this.audio.addEventListener("pause", () => {
      this.playing = false;
    });
    this.audio.addEventListener("play", () => {
      this.playing = true;
    });
    this.video.addEventListener("pause", () => {
      this.videoPlaying = false;
    });
    this.video.addEventListener("play", () => {
      this.videoPlaying = true;
    });
  },
};
</script>
<style lang="css" scoped></style>
